<template>
  <div class="home">
    <!-- VERTICAL BANNER -->
    <transition name="fade" v-show="project_template_panel == true">
      <div class="banner_container" :class="{ 'active': project_template_panel == true }">
        <p class="vertical_banner">
          {{ $t("Home.dashboard") }}
        </p>
      </div>
    </transition>

    <h1>
      {{ $t("Home.dashboard") }}
    </h1>

    <div class="cardList createProject center" style="height: unset;margin-top: 38px;">
      <!-- Nouveau projet -->
      <div @click="createProject()" class="tuile" id="new_project_from_scratch" style="--color : rgb(194, 152, 245);">
        <img class="tuile_icon" src="../../assets/pen_white.svg">


        <div class="tuile-title">
          <p style="font-weight: bold;">
            {{ $t('Home.newProject') }}
          </p>
          <p style=" margin-top: 0px;">
            {{ $t('Home.newProject_line_2') }}
          </p>
        </div>

        <div class="tuile-plus">
          <span>+</span>
        </div>
      </div>

      <!-- Import video -->
      <input ref="file_input" style="display: none;" type="file" accept="video/*" @change="handelFile" />

      <div @click="$refs.file_input.click()" class="tuile" id="new_project_from_media"
        style="--color : rgb(244, 180, 106);">
        <img class="tuile_icon" src="../../assets/upload_file_white.png" />
        <div class="tuile-title">
          <template v-if="upload_progress == null">
            <p style="font-weight: bold;">
              {{ $t('Home.importVideo') }}
            </p>
            <p style=" margin-top: 0px;">
              {{ $t('Home.addCaptions') }}
            </p>
          </template>
          <template v-else>
            <p style="font-weight: bold;">{{ upload_progress }}%</p>
          </template>
        </div>

        <div class="tuile-plus">
          <span>+</span>
        </div>
      </div>

      <!-- Template -->
      <div @click="project_template_panel = true" class="tuile" id="new_project_from_template"
        style="--color: rgb(68, 227, 169);">
        <img class="tuile_icon" src="../../assets/video_media.png" />

        <div class="tuile-title">
          <p style="font-weight: bold">
            {{ $t('Home.templates') }}
          </p>
          <p style=" margin-top: 0px;">
            {{ $t('Home.startWithTemplate') }}
          </p>
        </div>

        <div class="tuile-plus">
          <span>+</span>
        </div>
      </div>
    </div>



    <h1>
      {{ $t("Home.myProjects") }}
    </h1>


    <!-- CLICK ZONE TO CLOSE PANEL -->
    <div v-if="project_template_panel != null" class="click_zone_to_close_panel" @click="project_template_panel = null">


    </div>

    <!-- NEW VIEW HELPER / CHOOSING TEMPLATE -->
    <transition name="panel-slide">
      <VideoTemplate v-if="project_template_panel == true" panel-position="0" class="panel video_template_panel"
        @closed="project_template_panel = false" />
    </transition>

    <!-- VIDEOS -->

    <div class="cardList center" id="projects_list">

      <!-- PROJECT -->
      <div v-for="project in getProjects()" v-bind:key="project" class="card" :project_id="project._id"
        @click="editProject(project._id, $event)">
        <DotsVdoMenu class="more" :lines="[
          $t('Rename'),
          $t('Duplicate'),
          $t('CreativeSpace.saveAsTemplate'),
          $t('Delete')
        ]" :red="[$t('Delete')]" @clicked="threeDotsClicked($event, project._id)" />
        <!-- VISUAL OF CARD -->
        <div class="visual_container">
          <p class="video_duration">
            {{ vdo_duration_render(project.project_duration) }}
          </p>
          <!-- <img src="../../assets/custom_background.jpg" /> -->
          <img v-if="project.thumbnail != null" :src="project.thumbnail" />
          <img v-else src="../../assets/custom_background.jpg" />
        </div>

        <div class="card-bottom">
          <div style="">
            <editableText v-model="project.project_name" :id="project._id" class="card-title"
              @update:modelValue="project_name_update($event, project._id)" :editable="true" type="text"
              :class="{ placeholder: project.project_name == '' }" :placeholder="$t('IndicateTitle.project')"
              @click.stop="" />

            <p class="card-date">
              {{ displayDate(project.created_at, $i18n.locale) }}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
//STORE
import Vuex from "vuex";
import axios from "axios";
//COMPONENTS

import DotsVdoMenu from "../../components/Object/ContextualMenu/ContextualMenu";
import editableText from "../../components/Object/editableText";
import VideoTemplate from "../../components/Panels/Video_templates/Video_templates";

//LIB
import { displayDate, renderMs } from "../../lib/date-time-duration.js";

export default {
  components: {
    editableText,
    DotsVdoMenu,
    VideoTemplate,
  },
  data() {
    return {
      //list_usrId: null,
      project_template_panel: false,
      project_id_created: null,
      upload_progress: null
    };
  },
  name: "Tableau de bord",
  mounted() {
    this.refresh_user_info();
    this.loadProjects(); //LOAD VIDEOS WITH CURRENT USER ID SET IN STOR
  },
  unmounted() {
    // projects: []
    this.$store.commit('CLEAR_PROJECTS')
  },
  computed: {
    ...Vuex.mapGetters(["isAdmin", "getProjects", "getProject", "getCurrentUsrId"]),
  },
  methods: {
    vdo_duration_render: renderMs,
    displayDate,
    ...Vuex.mapActions([
      "refresh_user_info",
      "loadProjects",
      "updateProject",
      "deleteProject",
      "duplicateProject", ,
      "addProject"
    ]),

    createProject() {
      this.addProject().then((project) => {
        this.$router.push(`/edit/${project._id}`);
      });
    },
    editProject(id, evt) {
      //IF CTRL KEY PRESS, OPEN IN NEW TAB
      if (evt.ctrlKey == true || evt.metaKey == true) {
        window.open(`${location.protocol}//${window.location.hostname}${(location.port ? ":" + location.port : "")}/edit/${id}`, '_blank').focus()
      } else {
        this.$router.push({ path: `/edit/${id}/` });
      }
    },
    project_name_update(value, id) {
      this.updateProject({
        update: {
          ids: [id],
          project_name: value.replace(/\s/g, "") == "" ? "" : value,
        },
      }).catch(() => {
        this.loadProjects()
      });
    },
    threeDotsClicked(event, id) {
      var choice = event.line;

      switch (choice) {
        //RENAME
        case this.$t("Rename"):
          $(`.card[project_id=${id}] .card-bottom .text_container`)[0].click();
          break; ``
        //DUPLICATE
        case this.$t("Duplicate"):
          this.duplicateProject(id)
            .then(() => {
              this.Toaster.success(this.$t("Home.success.duplication"));
              let scrollBar = document.querySelectorAll(
                ".sectionForVideoList"
              )[0];
              scrollBar.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            })
            .catch(() => { });
          break;

        //SAVE AS TEMPLATE
        case this.$t('CreativeSpace.saveAsTemplate'):
          axios.post('/templates', { project_id: id })
            .then(() => {
              this.Toaster.success(this.$t("Toaster.templates.template_saved_successfully"))
            })
            .catch(() => {
              this.Toaster.error(this.$t("Toaster.templates.template_saved_failed"))
            })

          break;
        case this.$t("Delete"):
          this.Toaster.prompt({
            title: this.$t('Toaster.areYouSure'),
            message: this.$t('Home.prompt.will_delete_project'),
            validate: this.$t('Delete'),
            cancel: this.$t('Cancel')
          }).then((response) => {
            if (response == "validate") {
              this.deleteProject(id);
            }
          })
          break;
      }
    },
    async handelFile(evt) {
      try {
        let file = evt.target.files[0]
        if (file == undefined) {
          return;
        }
        if (/video/.test(file.type) == false) {
          throw "E0403";
        }
        let formData = new FormData()
        formData.append('file', file)
        formData.append('usr_id', this.getCurrentUsrId)
        this.upload_progress = 0

        if (file.size / 1024 / 1024 / 1024 > 5) {
          throw "E0401";
        }

        //UPLOAD
        await axios.post('/projects', formData,
          {
            //REPORT UPLOAD PROGRESS
            onUploadProgress: (progressEvent => {
              this.upload_progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            })
          })
          .then(async (res) => {
            let clip_id = res.data.project_timeline[0]._id
            this.$router.push({
              path: `/edit/${res.data._id}`, query: {
                clip_id
              }
            });
          })
          .catch((err) => {
            throw err
          })

      } catch (err) {
        this.upload_progress = null
        console.error(err?.response?.data || err)
        if (err == "E0401" || err?.response?.data?.errorCode == "E0401") {
          this.Toaster.warn(this.$t("CatalogPanel.error.mediaTooHeavy"));
        } else if (err?.response?.data?.errorCode == "E0402") {
          this.Toaster.warn(this.$t("CatalogPanel.error.mediaTooLong", { time: this.maximal_time }));
        } else if (err == "E0403" || err?.response?.data?.errorCode == "E0403") {
          this.Toaster.warn(this.$t("CatalogPanel.error.videoFormatRequired"));
        } else if (err?.response?.data?.code == "notEnoughCredit" || err?.response?.data?.code == "missingCredit") {
          this.Toaster.prompt({
            title: this.$t('CatalogPanel.error.notEnoughCredit'),
            message: this.$t('CatalogPanel.error.doYouWantToUpgrade'),
            validate: this.$t('Upgrade'),
            cancel: this.$t('no'),
          }).then((response) => {
            if (response == "validate") {
              this.$router.push('/profile/subscriptions')
            }
          })
        } else {
          this.Toaster.error(this.$t('Toaster.error.something_whent_wrong'))
        }
      }
    }
  },
};
</script>



<style src="./home.css" scoped></style>

<style src="./listEmpty.css" scoped></style>

