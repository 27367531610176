<template>
  <div class="panel" panel-position="0">
    <h1 class="arian">
      <a @click="$emit('goToHome')">{{ $t("Home.dashboard") }}</a>
      <a> / </a>
      <a @click="$emit('closed')">{{ $t("VideoTemplates.title") }}</a>
      <a> / </a>
      <a v-if="template.timeline_template_name != ''">{{
        template.timeline_template_name
      }}</a>
      <a v-else>{{ $t('IndicateTitle.untitled') }}</a>
    </h1>

    <!-- HEADER -->
    <div class="header center">
      <div>
        <h2 style="display: flex">
          <span class="template_color" :style="`background-color : ${template.color}`"></span>
          <editableText v-model="templateName" :id="'template_name'" :editable="editableTitle" type="text" :placeholder="$t('IndicateTitle.template')" />
        </h2>
        <p>{{ template.timeline_template_description }}</p>
      </div>
      <ContextualMenu v-if="template.usr_id != undefined &&
        template.usr_id == this.getCurrentUser._id
        " style="width: 25px" :lines="buttonsThreeDots(template)" @clicked="threeDotsClicked($event, template)" :red="$t('Delete')" class="ContextualMenu"></ContextualMenu>
      <p class="button applyModel" @click="applyTemplate()">
        {{ $t("VideoTemplates.use") }}
      </p>
    </div>

    <!-- CONTENU -->
    <div class="template_content center">
      <h3>{{ $t("VideoTemplates.content") }}</h3>
      <p>
        {{ $t("VideoTemplates.description") }}
      </p>

      <div v-for="clip in template.timeline_template_data" :key="clip" class="clip">
        <!-- ANIMATIONS -->
        <img v-if="clip.clip_type == 0 && clip.anim_type == 0" src="../../../../../assets/animation.svg" style="background-color: #44bce1" class="icon" />

        <img v-if="clip.clip_type == 0 && clip.anim_type == 1" src="../../../../../assets/title.svg" style="background-color: #44e3a9" class="icon" />
        <img v-if="clip.clip_type == 0 && clip.anim_type == 2" src="../../../../../assets/animation.svg" style="background-color: #c298f5" class="icon" />

        <p class="text" v-if="clip.clip_type == 0 && clip.anim_type == 0">
          <span>{{ $t("ScriptPart.animation.intro") }}</span>
        </p>
        <p class="text" v-if="clip.clip_type == 0 && clip.anim_type == 1">
          <span v-if="clip.title_content != undefined && clip.title_content != ''">{{ clip.title_content }}</span>
          <span v-else>{{ $t("ScriptPart.animation.title") }}</span>
        </p>
        <p class="text" v-if="clip.clip_type == 0 && clip.anim_type == 2">
          <span>{{ $t("ScriptPart.animation.outro") }}</span>
        </p>

        <!-- CLIP TYPE 1-->
        <img v-if="clip.clip_type == 1" src="../../../../../assets/icon_script_part_speaker.svg" style="background-color: #f4b46a" class="icon" />
        <p class="text" v-if="clip.clip_type == 1">{{ clip_media_title(clip) }}</p>
      </div>
    </div>
  </div>
</template>


<script>
//STORE
import Vuex from "vuex";
import axios from "axios";

import ContextualMenu from "../../../../Object/ContextualMenu/ContextualMenu.vue";
import editableText from "../../../../Object/editableText.vue";

export default {
  props: ["template", "vdo_id"],
  event: "closed",
  $emit: ['update'],
  components: {
    ContextualMenu,
    editableText,
  },
  computed: {
    ...Vuex.mapGetters(["getCurrentUser"]),
    editableTitle() {
      return (
        this.template.usr_id != undefined &&
        this.template.usr_id == this.getCurrentUser._id
      );
    },
    templateName: {
      get() { return this.template.timeline_template_name },
      set(value) {
        axios.put("/templates/" + this.template._id, {
          timeline_template_name: value,
        }).then((res) => {
          this.$emit('update', res.data)
        })
      }
    }
  },
  methods: {
    ...Vuex.mapActions(["addProject"]),
    close() {
      this.$emit("closed");
    },
    applyTemplate() {
      this.addProject({
        template_id: this.template._id
      })
        .then((project) => {
          this.$router.push(`/edit/${project._id}`);
        })
        .catch(() => {
          this.Toaster.error(this.$t("Toaster.error.add"));
          this.$emit("closed");
        });
    },
    buttonsThreeDots(template) {
      let array = [this.$t('Rename')];
      if (this.getCurrentUser.team != undefined) {
        if (template.team_id == undefined) {
          array.push(this.$t('Share'));
        } else {
          array.push(this.$t('StopSharing'));
        }
      }
      array.push(this.$t('Delete'));
      return array;
    },
    threeDotsClicked(event, template) {
      var choice = event.line;

      switch (choice) {
        case this.$t('Rename'):
          $(".text_container#" + template._id + " > a")[0].click();
          break;
        case this.$t('Share'):
          axios.put("/templates/" + template._id, {
            team_id: this.getCurrentUser.team.id,
          }).then((res) => {
            this.$emit('update', res.data)
          })
          break;
        case this.$t('StopSharing'):

          axios.put("/templates/" + template._id, {
            team_id: null,
          }).then((res) => {
            this.$emit('update', res.data)
          })
          break;
        case this.$t('Delete'):
          axios.delete("/templates/" + template._id);
          this.$emit("delete", template._id);
      }
    },
    clip_media_title(clip_data) {
      try {
        if (clip_data.media?.transcription?.text != undefined && clip_data.media?.transcription?.text != null && clip_data.media?.transcription?.text != "") {
          return '"' + clip_data.media?.transcription.text + '"'
        } else if (clip_data.scrpt_text != undefined && clip_data.scrpt_text != "") {
          return '"' + clip_data.scrpt_text + '"'
        } else if (clip_data.media?.media_name != undefined && clip_data.media?.media_name != null && this.clip_data.imported == true) {
          return clip_data.media?.media_name
        } else {
          return this.$t('ScriptPart.clip_type_1.title')
        }
      } catch (e) {
        return this.$t('ScriptPart.clip_type_1.title')
      }
    }
  }
};
</script>


<style src="./style.css" scoped></style>