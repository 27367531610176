<template>
  <div>
    <!-- TEMPLATE OVERVIEW -->
    <transition name="panel-slide">
      <TemplateOverview v-if="templateOverview != null" panel-position="0" class="TemplateOverview"
        :template="templateOverview" :vdo_id="vdo_id" @closed="templateOverview = null" @goToHome="$emit('closed')"
        @delete="deleteTemplate" @update="updateTemplate" />
    </transition>

    <!-- CLICK ZONE TO CLOSE PANEL -->
    <div v-if="templateOverview != null" class="click_zone_to_close_panel" @click="templateOverview = null"></div>

    <!-- VERTICAL BANNER -->
    <transition name="fade" v-show="templateOverview != null">
      <div class="banner_container" :class="templateOverview != null ? 'active' : ''">
        <p class="vertical_banner">
          {{ $t("VideoTemplates.title") }}
        </p>
      </div>
    </transition>

    <!-- PANEL CONTENT (SCROLL INTO PANEL HEIGHT)-->
    <div class="scroll_into_panel_height">
      <h1 class="arian">
        <a @click="$emit('closed')">{{ $t("Home.dashboard") }}</a>
        <a> / </a>
        <a>{{ $t("VideoTemplates.title") }}</a>
      </h1>

      <div class="choices center">
        <div class="choice" v-for="timelineTemplate in timelineTemplates_Colored" :key="timelineTemplate"
          @click="applyTemplate(timelineTemplate)">
          <span class="template_color" :style="`background-color : ${timelineTemplate.color}`"></span>
          <p>{{ timelineTemplate.timeline_template_name }}</p>
          <p class="type">{{ timelineTemplate.timeline_template_type || "-" }}</p>
        </div>
      </div>
      <section v-if="user_template.length != 0">
        <h1 style="margin-left: 5%">Mes templates</h1>
        <div class="choices center">
          <div class="choice" v-for="timelineTemplate in user_template" :key="timelineTemplate"
            @click="applyTemplate(timelineTemplate)">
            <img v-if="timelineTemplate.team_id" class="team_shared" src="../../../assets/teamwork.png" />
            <span class="template_color" :style="`background-color : ${timelineTemplate.color}`"></span>
            <p v-if="timelineTemplate.timeline_template_name != ''">
              {{ timelineTemplate.timeline_template_name }}
            </p>
            <p v-else>{{ $t('IndicateTitle.untitled') }}</p>
          </div>
        </div>
      </section>
      <section v-if="team_template.length != 0">
        <h1 style="margin-left: 5%">Templates de team</h1>
        <div class="choices center">
          <div class="choice" v-for="timelineTemplate in team_template" :key="timelineTemplate"
            @click="applyTemplate(timelineTemplate)">
            <span class="template_color" :style="`background-color : ${timelineTemplate.color}`"></span>
            <p v-if="timelineTemplate.timeline_template_name != ''">
              {{ timelineTemplate.timeline_template_name }}
            </p>
            <p v-else>{{ $t('IndicateTitle.untitled') }}</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>


<script>
//PANELS
import TemplateOverview from "./components/Panel_templateOverview/Panel_templateOverview.vue";

//STORE
import Vuex from "vuex";
export default {
  props: ["vdo_id"],
  data() {
    return {
      templateOverview: null,
    };
  },
  components: { TemplateOverview },
  event: closed,
  mounted() {
    this.fetch_timeline_template();
  },
  unmounted() {
    this.$store.commit('CLEAR_TEMPLATES')
  },
  computed: {
    ...Vuex.mapGetters(["timelineTemplates", "getCurrentUsrId"]),
    timelineTemplates_Colored() {

      return this.timelineTemplates.sort(( a, b ) =>{
          if ( a._id < b._id ){
            return -1;
          }
          if ( a._id > b._id ){
            return 1;
          }
          return 0;
        }).flatMap((template) => {
          var color = "#FFFFFF";
          if (template.timeline_template_type == "Marketing") {
            color = "#72B5E3";
          } else if (template.timeline_template_type == "Marque employeur" || template.timeline_template_type == "Employer branding") {
            color = "#E5AC70";
          } else if (template.timeline_template_type == "Publicité" || template.timeline_template_type == "Advertising") {
            color = "#85D6AD";
          } else if (template.timeline_template_type == "Support") {
            color = "#A72B2A";
          } else if (template.timeline_template_type == "Vente" || template.timeline_template_type == "Sale") {
            color = "#B9A6DF"; //Violet
          } else if (
            template.timeline_template_type == "Framework copywriting" ||  template.timeline_template_type == "Modèle d'écriture"
          ) {
            color = "#B9A6DF"; //Violet
          }

          return {
            ...template,
            color,
          };
        })
        .filter((t) => t.usr_id == undefined);
    },
    user_template() {
      return this.timelineTemplates
        .flatMap((template) => {
          return {
            ...template,
            color: "#F67CA8",
          };
        })
        .filter(
          (t) => t.usr_id != undefined && t.usr_id == this.getCurrentUsrId
        );
    },
    team_template() {
      return this.timelineTemplates
        .flatMap((template) => {
          return {
            ...template,
            color: "#A03866",
          };
        })
        .filter(
          (t) => t.usr_id != undefined && t.usr_id != this.getCurrentUsrId
        );
    },
  },
  methods: {
    ...Vuex.mapActions(["fetch_timeline_template", "addProject"]),
    applyTemplate(template) {
      if (template != undefined) {
        this.templateOverview = template;
      } else {
        this.addProject().then((project) => {
          this.$router.push(`/edit/${project._id}`);
        });
      }
    },
    goToHome() {
      this.$router.push(`/`);
    },
    deleteTemplate(id) {
      let idx = this.timelineTemplates.findIndex((t) => t._id == id);
      if (idx != -1) {
        this.timelineTemplates.splice(idx, 1);
      }
      this.templateOverview = null;
    },
    updateTemplate(data) {
      this.templateOverview = { ...data, color: this.templateOverview.color }
      let idx = this.timelineTemplates.findIndex((t) => t._id == data._id);
      if (idx != -1) {
        this.timelineTemplates.splice(idx, 1, data);
      }

    },
  },
};
</script>


<style src="./style.css" scoped>

</style>